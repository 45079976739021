<template>
    <div class="app-content app-container">
        <!-- 搜索 -->
        <div style="margin-bottom: 20px">
            <el-form
                :inline="true"
                :model="formInline"
                class="demo-form-inline"
                ref="formInline"
                @submit.native.prevent
            >
                <el-form-item label="活动名称" prop="activityName">
                    <el-input
                        style="width: 300px"
                        v-model="formInline.activityName"
                        placeholder="请输入"
                        clearable
                        @keyup.enter.native="onSubmit"
                    ></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="onSubmit">搜索</el-button>
                    <el-button @click="resetForm('formInline')">重置</el-button>
                </el-form-item>
            </el-form>
            <div>
                <el-button type="primary" plain @click="addReport"
                    >新增</el-button
                >
                <el-button type="danger" plain @click="allDeleteClick"
                    >删除</el-button
                >
            </div>
        </div>
        <!-- 表格 -->
        <div>
            <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange"
            >
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column
                    prop="id"
                    width="55"
                    label="#"
                ></el-table-column>
                <el-table-column prop="activityName" label="活动名称">
                </el-table-column>
                <el-table-column prop="applyNumber" label="报名人数">
                </el-table-column>
                <el-table-column prop="createTime" label="时间">
                </el-table-column>
                <el-table-column prop="status" label="是否启用">
                    <template slot-scope="scope">
                        <span>{{ scope.row.status | isYes }}</span>
                    </template>
                </el-table-column>

                <el-table-column label="操作" fixed="right" width="240">
                    <template slot-scope="scope">
                        <el-button type="text" @click="editClick(scope.row)"
                            >编辑</el-button
                        >
                        <el-button type="text" @click="deleteClick(scope.row)"
                            >删除</el-button
                        >
                        <el-button
                            type="text"
                            @click="importSignUpClick(scope.row)"
                            >导入报名</el-button
                        >
                        <el-button
                            type="text"
                            @click="seeSignUpClick(scope.row)"
                            >查看报名</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div>
                <Pagination
                    v-bind:child-msg="pageparm"
                    @callFather="callFather"
                ></Pagination>
            </div>
        </div>
        <!-- 导入弹窗 -->
        <el-dialog :title="title" :visible.sync="dialogVisible" width="50%">
            <div>
                <div>
                    下载导入模版：<a
                        href="/activeImport.xlsx"
                        class="down"
                        download="下载培训活动报名模板.xlsx"
                        >下载培训活动报名模板.xlsx</a
                    >
                </div>
                <div style="margin-top: 20px">
                    导入报名文件：
                    <el-upload
                        style="margin-top: 10px"
                        class="upload-demo"
                        drag
                        :limit="limit"
                        ref="upload"
                        :on-remove="handleRemove"
                        :file-list="fileList"
                        :on-change="handelChange"
                        :on-exceed="handleExceed"
                        name="cover"
                        :auto-upload="false"
                        action=""
                >
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">
                            将文件拖到此处，或<em>点击上传</em>
                        </div>
                        <div class="el-upload__tip" slot="tip">
                            只能上传(.xlsx/.xls)文件，且不超过500kb
                        </div>
                    </el-upload>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button
                    :loading="isSubmit"
                    type="primary"
                    @click="editSubmitClick('ruleForm')"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Pagination from '@/components/Pagination'
import {
    getTrainActivityListApi,
    getTrainActivityDeleteApi,
    getTrainActivitySignUpImportApi
} from '@/api/training'
import { getToken } from '@/utils/auth'
export default {
    // 注册组件
    components: {
        Pagination,
    },
    data() {
        return {
            title: '导入报名文件',
            dialogVisible: false,
            isSubmit: false,
            formInline: {},
            tableData: [],
            multipleSelection: [],
            pageparm: {
                currentPage: 1,
                pageSize: 10,
                total: 20,
            },
            action: '',
            fileSize: 5,
            limit:1,
            fileType:['xlsx','xls'],
            headers: {
                Authorization: getToken(),
            },
            fileList:[]
        }
    },
    created() {
        if (sessionStorage.getItem('formInline')) {
            this.formInline = JSON.parse(sessionStorage.getItem('formInline'))
        }
        this.getTrainActivityListFun()
    },
    methods: {
        // 获取列表
        async getTrainActivityListFun(currentPage, pageSize) {
            var start = currentPage ? currentPage * pageSize - pageSize : 0
            var params = {
                start: start,
                length: pageSize ? pageSize : 10,
                draw: 1,
                orderStr: 'id desc',
            }
            var p = Object.assign(params, this.formInline)
            var res = await getTrainActivityListApi(p)
            this.pageparm.total = res.recordsTotal
            this.tableData = res.data
        },
        onSubmit() {
            sessionStorage.setItem(
                'formInline',
                JSON.stringify(this.formInline)
            )
            this.getTrainActivityListFun()
        },
        resetForm(formInline) {
            sessionStorage.removeItem('formInline')
            this.formInline = {
                name: '',
            }
            this.$refs[formInline].resetFields()
            this.getTrainActivityListFun()
        },
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        // 分页插件事件
        callFather(parm) {
            this.getTrainActivityListFun(parm.currentPage, parm.pageSize)
        },
        // 新增模板
        addReport() {
            this.$router.push({
                path: '/trainingActivity/add',
            })
        },
        // 编辑模板
        editClick(row) {
            this.$router.push({
                path: '/trainingActivity/add',
                query: {
                    id: row.id,
                },
            })
        },
        // 导入报名数据
        async importSignUpClick(row) {
            this.dialogVisible = true
            this.action =row.id
            // console.log('row', row);
            // var res = await getTrainActivitySignUpImportApi()
            // console.log('res', res);
        },
        // 提交报名
        async editSubmitClick() {
            this.isSubmit = true
            // 回调成功 isSubmit=false
             //   这里需要判断一下文件大小或者类型
            //   自定义上传就需要我们使用fromdata对象来上传文件
            let formdata = new FormData()
            for (let i = 0; i < this.fileList.length; i++) {
                // 我们上传的文件保存在每个文件对象的raw里边
                formdata.append('file', this.fileList[i].raw)
            }
            // 发送请求
            var res = await getTrainActivitySignUpImportApi(this.action, formdata)
              if(res.code === 200){
                this.dialogVisible=false
                this.$message.success(res.msg)
              }
        },
        handelChange(file, fileList) {
            if (this.fileType) {
                const fileName = file.name.split('.')
                const fileExt = fileName[fileName.length - 1]
                const isTypeOk = this.fileType.indexOf(fileExt) >= 0
                console.log('isTypeOk', isTypeOk);
                if (!isTypeOk) {
                    this.$modal.msgError(
                        `文件格式不正确, 请上传${this.fileType.join(
                            '/'
                        )}格式文件!`
                    )
                    this.fileList = []
                    return false
                }else{
                    console.log('000');
                    this.fileList = fileList
                }
            }
           
        },
        
        handleRemove(file, fileList) {
            this.filesList = fileList
        },
        handleExceed() {
           this.$modal.msgError(`上传文件数量不能超过 ${this.limit} 个!`);
        },
        beforeRemove(file) {
            return this.$confirm(`确定移除 ${file.name}？`)
        },
        // 查看报名数据
        seeSignUpClick(row) {
            this.$router.push({
                path: '/trainingActivity/preview',
                query: {
                    id: row.id,
                    activityName: row.activityName,
                },
            })
        },
        // 删除多个
        allDeleteClick() {
            var ids = this.multipleSelection.map((item) => item.id)
            this.$alert(
                '这确定要删除共' +
                    this.multipleSelection.length +
                    '条数据吗？删除后将无法恢复',
                '删除提示',
                {
                    confirmButtonText: '确定',
                    callback: async (action) => {
                        console.log('action', action)
                        if (action === 'confirm') {
                            var params = {
                                ids: ids,
                                menuType: 'delete',
                            }
                            var res = await getTrainActivityDeleteApi(params)
                            console.log('删除结果', res)
                            if (res.code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: '删除成功',
                                })
                                this.getTrainActivityListFun()
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: '删除失败：' + res.message,
                                })
                            }
                        }
                    },
                }
            )
        },
        // 删除
        deleteClick(item) {
            var ids = []
            ids.push(item.id)
            this.$alert(
                '这确定要删除' + item.activityName + '吗？删除后将无法恢复',
                '删除提示',
                {
                    confirmButtonText: '确定',
                    callback: async (action) => {
                        if (action === 'confirm') {
                            var params = {
                                ids: ids,
                                menuType: 'delete',
                            }
                            var res = await getTrainActivityDeleteApi(params)
                            if (res.code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: '删除成功',
                                })
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: '删除失败：' + res.message,
                                })
                            }
                            this.getTrainActivityListFun()
                        }
                    },
                }
            )
        },

        
        
    },
}
</script>
<style scoped>
.app-content {
    background-color: #fff;
    padding: 20px;
}
</style>
