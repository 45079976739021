import request from '@/utils/request.js'

// 培训活动 --- 列表
export function getTrainActivityListApi(data) {
  return request({
    url: '/doftec/trainActivity/list',
    method: 'post',
    data: data
  })
}

// 培训活动 --- 添加
export function getTrainActivityCreateApi(data) {
  return request({
    url: '/doftec/trainActivity/create',
    method: 'post',
    data: data
  })
}

// 培训活动 --- 更新
export function getTrainActivityUpdateApi(data) {
  return request({
    url: '/doftec/trainActivity/update',
    method: 'post',
    data: data
  })
}



// 培训活动 --- 删除 ids*	id数组[]  menuType*	操作状态：delete 删除 push 推送 download 下载
export function getTrainActivityDeleteApi(data) {
  return request({
    url: '/doftec/trainActivity/action',
    method: 'post',
    data: data
  })
}

// 培训活动 --- 详情
export function getTrainActivityInfoApi(params) {
  return request({
    url: '/doftec/trainActivity/info',
    method: 'get',
    params: params
  })
}

// 培训活动 --- 报名详情
export function getTrainActivitySignUpInfoApi(params) {
  return request({
    url: '/doftec/trainActivityUser/list',
    method: 'post',
    params: params
  })
}

// 培训活动 --- 导入报名
export function getTrainActivitySignUpImportApi(id,data) {
  return request({
    url: '/doftec/trainActivityUser/list/'+id+'/import',
    method: 'post',
    data: data
  })
}

// 培训课程 --- 列表
export function getTrainCourseListApi(data) {
  return request({
    url: '/doftec/trainCourse/list',
    method: 'post',
    data: data
  })
}

// 培训课程 --- 添加
export function getTrainCourseCreateApi(data) {
  return request({
    url: '/doftec/trainCourse/create',
    method: 'post',
    data: data
  })
}

// 培训课程 --- 更新
export function getTrainCourseUpdateApi(data) {
  return request({
    url: '/doftec/trainCourse/update',
    method: 'post',
    data: data
  })
}

// 培训课程 --- 删除 ids*	id数组[]  menuType*	操作状态：delete 删除 push 推送 download 下载
export function getTrainCourseDeleteApi(data) {
  return request({
    url: '/doftec/trainCourse/action',
    method: 'post',
    data: data
  })
}

// 培训课程 --- 详情
export function getTrainCourseInfoApi(params) {
  return request({
    url: '/doftec/trainCourse/info',
    method: 'get',
    params: params
  })
}